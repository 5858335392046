import React, { Component } from 'react'

import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import Img from 'Components/UI/Img'
import Breadcrumbs from 'Components/UI/Breadcrumbs'

class AboutPage extends Component {
  constructor(props) {
    super(props)
    this.breadcrumbsData = [
      {
        path: `${this.props.location.pathname}`,
        title: 'Our Story'
      }
    ]
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <Layout>
        <SeoHead
          title="Our Story"
          path={this.props.location.pathname}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        <div className='u-height--120' />
        <div className={`u-offset-md-2 u-col-md-8 u-offset-lg-1 u-col-lg-6 u-pad-x--md`}>
          <h1 className={`t-big-title u-uppercase`}>Our story</h1>
          <div className='u-height--20' />
          <p className={`t-paragraph-2`}>The Stella Luna brand, born in 2006, is the result of a unique combination of ingredients: a family with generations of manufacturing and craftsmanship experience, a European design team, a reliance on the best of Italian leathers and a founder trained in engineering and design. Known for its strong commitments to modern, versatile style and true craftsmanship, and dedicated to assuring comfort at all heel heights, Stella Luna — already well-established in Asia — has grown to be a truly international brand in recent years, opening shops across the Middle East , as well as three boutiques in Paris. The brand is carried exclusively in Hong Kong at Lane Crawford and in the United States at Barneys New York.</p>
          <div className='u-height--40' />
          <h2 className={`t-title-1 u-uppercase`}>A COMMITMENT TO QUALITY</h2>
          <div className='u-height--20' />
          <p className={`t-paragraph-2`}>Stella Luna’s quality standards are quite simple to understand: it will only settle for the best. So, it’s not surprising that the company’s design and leather teams are located in Riviera del Brenta, in the Northeastern corner of Italy. To access the world’s best leathers, this is where one needs to be. Tanneries here are second to none in their sourcing expertise, sophistication and precision. Stella Luna’s Riviera del Brenta team, headed by Italians whose families have generations of leather experience, stands out in particular due to its drive to master the latest technical advances and production innovations.</p>
        </div>
        <div className='u-height--120' />
        <div className={`u-offset-md-1 u-col-md-10 u-pad-x--md`}>
          <div className="u-row">
            <div className='u-col-md-5'>
              <Img src={`${AppConstants.S3_URL}about-page/stephen-chi.jpg`} srcRetina={`${AppConstants.S3_URL}about-page/stephen-chi.jpg`} alt='Stephen Chi' />
            </div>
            <div className='u-offset-md-1 u-col-md-6 u-pad-y-top--md'>
              <div className="u-vertical-center-parent">
                <div className="u-vertical-center-child u-text-left">
                  <h1 className={`t-big-title u-uppercase`}>STEPHEN CHI</h1>
                  <div className='u-height--20' />
                  <h2 className={`t-title-1 u-uppercase`}>FOUNDER AND CEO</h2>
                  <div className='u-height--20' />
                  <p className={`t-paragraph-2`}>Coming from a family known for its manufacturing know-how and its commitment to craftsmanship, Stephen Chi was marked from a very early age by a drive to understand and master the innovations and technology behind great design. That passion guided him through his engineering studies in America and continues to propel him today, as he oversees each of Stella Luna’s collections. Born in Taiwan, Stephen grew up in Hawaii and Northern California. Today, he remains constantly on the move—leading his French and Italian design and leather teams, overseeing his boutiques around the world and guiding his factory’s team of craftsmen.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='u-height--120' />
      </Layout>
    )
  }
}

export default AboutPage
